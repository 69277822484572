<template>
  <div class="login">
    <div class="box-wrap">
      <div class="logo">
        <img src="../assets/images/dayungs_logo2.png" alt="" />
      </div>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        status-icon
        hide-required-asterisk
        :rules="rules"
        label-position="top"
        style="margin-top: 30px"
      >
        <el-form-item prop="shop" class="form-item">
          <template #label>
            <p class="label">門市</p>
          </template>
          <el-cascader
            v-model="ruleForm.shop"
            :options="getAllStoreList"
            :show-all-levels="false"
            placeholder="請選擇或輸入門市名稱、代碼"
            style="width: 100%"
            clearable
            filterable
            :props="{ emitPath: false }"
          />
        </el-form-item>
        <el-form-item prop="account" class="form-item">
          <template #label>
            <p class="label">帳號</p>
          </template>
          <el-input v-model="ruleForm.account" placeholder="請輸入帳號" />
        </el-form-item>
        <el-form-item prop="password" class="form-item">
          <template #label>
            <p class="label">密碼</p>
          </template>
          <el-input v-model="ruleForm.password" type="password" placeholder="請輸入密碼" show-password />
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        size="large"
        style="width: 100%; margin-top: 30px"
        @click="handleLogin(ruleFormRef)"
        :disabled="!ruleForm.shop || !ruleForm.account || !ruleForm.password"
      >
        登入
      </el-button>
      <small>version: {{ getVersion }}</small>
    </div>
  </div>
</template>
<script>
import { ref, reactive } from 'vue';
import { useGetters, useActions } from '@/utils/mapStore';
import { useRouter } from 'vue-router';
export default {
  name: 'log-in',
  setup() {
    //取得版號和店家清單
    const doGetStore = useActions('user', ['doGetStore']);
    doGetStore().catch((e) => e);
    const { getAllStoreList, getVersion, getEnvironment } = useGetters('user', [
      'getAllStoreList',
      'getVersion',
      'getEnvironment',
    ]);
    //設定參數
    const ruleFormRef = ref(null);
    //設定初始值
    const ruleForm = reactive({
      shop: '',
      account: `${getEnvironment.value === 'release' ? '' : 'test1'}`,
      password: `${getEnvironment.value === 'release' ? '' : '111111'}`,
    });
    //驗證
    const rules = reactive({});
    //送出
    const router = useRouter();
    const doLogin = useActions('user', ['doLogin']);
    const handleLogin = async (form) => {
      try {
        if (!form) return;
        await form.validate();
        ruleForm.shop_id = ruleForm.shop.split(' ')[0];
        await doLogin(ruleForm);
        router.push('/');
      } catch (e) {
        e;
      }
    };
    return { ruleFormRef, ruleForm, getAllStoreList, getVersion, rules, handleLogin };
  },
};
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  background: url(../assets/images/bg.jpg);
  background-size: cover;
  @include flex-center-center;
  .box-wrap {
    width: 520px;
    height: 620px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 6px 6px 10px 0 rgba(75, 96, 132, 0.44);
    color: $color-text;
    letter-spacing: 1px;
    padding: 50px 70px;
  }
  .logo {
    width: 110px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .form-item {
    margin-bottom: 30px;
    .label {
      margin-bottom: 15px;
      font-size: 1.6rem;
    }
  }
}
</style>
